import axios from '@/utils/axios.js';

const get = async (tipo = "", relacionamento_id) => {
    let anexos = [];

    await axios.get('/anexos/get', { params: { tipo, relacionamento_id } }).then((result) => {
        anexos = result.data
    })

    return anexos.data;
}



const store = async (data) => {
    try {
        return (await axios.post('/anexos/store', data)).data;
    } catch (error) {
        console.log(error)
    }

}

const del = async (data) => {
    return (await axios.post('/anexos/delete', data)).data;
}
export default {
    get,
    store,
    del
}