const niveis = [
  { value: "admin", text: "Administrador" },
  { value: "atendente", text: "Atendente" },
  { value: "secretaria", text: "Secretária(o)" },
  { value: "medico", text: "Médico(a)" },
  { value: "Entidade", text: "Entidade" },
  { value: "laboratorio", text: "Laboratório" },
  { value: "paciente", text: "Paciente" },
  { value: "gerente", text: "Gerente" },
];
const onlyValues = niveis.map((e) => e.value);
module.exports = { niveis, onlyValues };
