<template>
  <div>
    
    <div v-if="tipo.indexOf('image') >= 0 || tipo.indexOf('img') >= 0 ">
      <b-img  thumbnail fluid-grow width="100%" :src="src" />
    </div>

    <div v-else-if="tipo.indexOf('pdf') >= 0">
      <iframe width="100%" height="1000px" :src="src" frameborder="0"></iframe>
    </div>
   <div v-else >
     
       <object :data="src" :type="tipo"    width="100%" height="1000px"></object>
   </div>
  </div>
</template>

<script>

export default {
  components: {
  
  },
  props: {
    tipo: String,
    src: String,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
