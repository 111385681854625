<template>
  <div>
    
    <div class="row">
      <div class="col">
 
          <clipper-basic
            :src="src"
            preview="preview"
            ref="clipper"
            :rotate="objEdit.rotation"
            :init-width="100"
            :init-height="100"
            :wrapRatio="objEdit.ratio"
            bgColor="black"
            
            :width="1900"
             v-show="tp==1"

             
          ></clipper-basic>
      
      </div>
      <!-- <div class="col-6">
        <div class="row">
          <h4>Pré-visualização</h4>
          <clipper-preview name="preview"></clipper-preview>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    objEdit:Object
  },
  data() {
    return {
      blob: null,
      tp:1
      // url: "",
    };
  },
  methods: {
    async getBlob() {
      URL.revokeObjectURL(this.src)
      const canva = this.$refs.clipper.clip()
      console.log(canva);
      await canva.toBlob((blob) => {
        console.log(blob);
        this.blob = blob;
        // if (this.url) {
        //   URL.revokeObjectURL(this.url);
        // }
        // this.url = URL.createObjectURL(blob);
        this.$emit("blob", this.blob);
      });
    },
  },
  destroyed(){
     URL.revokeObjectURL(this.src)
  }
};
</script>

<style>
</style>