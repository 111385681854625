<template>
  <div>
    <b-form @submit.stop.prevent="salvar()">
      <div
        class="d-flex justify-content-end align-items-center"
        v-if="uploading"
      >
        <b-spinner class="mr-2" small></b-spinner>
        <span class="text-muted">Carregando...</span>
      </div>
      <b-form-group>
        <div class="row">
          <div class="col-12">
            <label>Titulo</label>
            <b-form-input
              :disabled="uploading"
              v-model="anexo.titulo"
              placeholder="Titulo"
            ></b-form-input>
          </div>
        </div>
      </b-form-group>
      <b-form-group>
        <div class="row">
          <div class="col-6">
            <label>Data Referência</label>
            <b-form-input
              type="date"
              :disabled="uploading"
              v-model="anexo.data_referencia"
            ></b-form-input>
            <small class="text-muted">
              * Atenção à data de referência
            </small>
          </div>
          <div class="col-6">
            <label>Nivel de Acesso</label>
            <b-form-select
              :disabled="uploading"
              v-model="anexo.nivel"
              :options="niveis"
            ></b-form-select>
          </div>
        </div>
      </b-form-group>
      <b-form-group>
        <div class="row">
          <div class="col-12">
            <label>Observação</label>
            <b-form-textarea
              :disabled="uploading"
              v-model="anexo.observacao"
              placeholder="Observação"
            ></b-form-textarea>
          </div>
        </div>
      </b-form-group>

      <!-- <b-form-file
        type="file"
        ref="txtFile"
        @change="setImagem"
        placeholder="Selecione alguma imagem ou documento"
      /> -->

      <small class="text-muted">
        * Clique em algum arquivo para expandir
      </small>
      <FileUpload
        :lista="anexo.arquivos"
        @addFile="onAddFile"
        @removeFile="onRemoveFile"
        @fileClicked="visualizarAnexo($event)"
      />

      <div
        class="row mt-5 justify-content-end border-top pt-3"
        v-if="showButtons"
      >
        <div class="col d-flex justify-content-end">
          <b-btn
            :disabled="uploading"
            @click="onSalvar"
            class="mr-3"
            variant="primary"
          >
            <i class="fas fa-save" v-if="!uploading"></i>
            <b-spinner small v-if="uploading"></b-spinner> Anexar</b-btn
          >
          <b-btn @click="onCancelar" variant="warning">
            <i class="fas fa-ban"></i> Cancelar
          </b-btn>
        </div>
      </div>
    </b-form>

    <b-modal id="modal-edicao" size="xl">
      <template #modal-title>
        <i class="fas fa-image"></i> Editar Imagem
      </template>

      <EditaFoto
        :objEdit="objEdit"
        ref="EditaFoto"
        :src="arquivoVisualizando.url"
        @blob="handleImagemEditada($event)"
      />

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <div>
            <b-btn variant="none" @click="downloadFile"
              ><b-icon-download /> Salvar</b-btn
            >
            <b-btn variant="none" @click="PrintFile"
              ><b-icon-printer /> Imprimir</b-btn
            >
          </div>
          <div>
            <b-btn variant="transparent" @click="editaImagem">
              <i class="fas fa-cut"></i> Finalizar</b-btn
            >
            <b-btn variant="transparent" @click="girar">
              <i class="fas fa-redo-alt"></i> Girar</b-btn
            >
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal id="modal-visualizacao" size="xl" hide-footer>
      <template #modal-title>
        <i class="fas fa-eye"></i> Visualizando
        <b> {{ anexo.titulo.toUpperCase() }}</b>
      </template>
      <VisualizarArquivo
        :tipo="arquivoVisualizando.type"
        :src="arquivoVisualizando.url"
      />
    </b-modal>
  </div>
</template>

<script>
import FilesLib from "../../libs/FilesLib";
import AnexosLib from "../../libs/AnexosLib";
import EditaFoto from "../common/EditaFoto.vue";
import VisualizarArquivo from "../common/VisualizarArquivo.vue";
import { niveis } from "../../helpers/niveisUsuario";
import moment from "moment";
import axios from "axios";
import FileUpload from "../common/FileUpload.vue";
import PrinterWindowHelper from "../../helpers/PrinterWindowHelper";

export default {
  props: {
    tipo: { required: true, type: String },
    id_relacionamento: { type: [String, Number] },
    pacienteNome: { default: "", type: [String] },
    anexoEdit: { tpye: Object, required: false },
    loading: { required: false, type: Boolean },
    showButtons: { default: false, type: Boolean },
    isFicha: { default: false, type: Boolean },
    preData: { default: () => {}, type: Object },
  },
  components: {
    EditaFoto,
    VisualizarArquivo,
    FileUpload,
  },
  data() {
    return {
      anexo: {
        url: "",
        tipo: this.$props.tipo,
        tipo_arquivo: "",
        observacao: "",
        arquivos: [],
        titulo: "",

        data_referencia: moment().format("YYYY-MM-DD"),
        nivel: "",
      },
      objEdit: {
        rotation: 0,
        ratio: 1,
        img: null,
      },
      arquivoVisualizando: {},
      editando: false,
      urlFormatado: "",
      token: this.$store.state.auth.user.token,

      uploading: false,
    };
  },
  methods: {
    PrintFile() {
      PrinterWindowHelper(
        `<img src="${this.arquivoVisualizando.url}" style="width: auto; max-height: 100%; max-width: 100%;"/>`,
        10,
        true
      );
    },
    downloadFile() {
      // window.open(this.arquivoVisualizando.url);
      var link = document.createElement("a");
      link.href = this.arquivoVisualizando.url;
      link.download = this.anexo.titulo;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    girar() {
      this.objEdit.ratio = 1;
      // this.objEdit.img.width > this.objEdit.img.height ? 1 : 0;
      this.objEdit.rotation += 90;
    },
    onAddFile(file) {
      // console.log("newFile ", file);
      if (file) {
        this.anexo.arquivos.push({
          ...file,
          _id: this.anexo.arquivos.length + 1,
        });
      }
    },
    onRemoveFile(file) {
      let idx = this.anexo.arquivos.findIndex((f) => f._id == file._id);
      // console.log('idx', idx);
      if (this.anexo.arquivos[idx]?.status === "added") {
        this.anexo.arquivos.splice(idx, 1);
      } else {
        this.$set(this.anexo.arquivos[idx], "status", "deleted");
      }
    },
    setImagem(event) {
      this.uploading = true;
      event.preventDefault();
      this.anexo.file = event.target.files[0];
      this.anexo.tipo_arquivo = this.anexo.file.type;
      this.anexo.url = URL.createObjectURL(this.anexo.file);
      this.uploading = false;

      this.$forceUpdate();
      this.formataUrl();
    },
    abrirEdicao() {
      for (const editField in this.objEdit) {
        this.objEdit[editField] = 0;
      }
      this.$bvModal.show("modal-edicao");
    },
    formataUrl(url) {
      // console.log(this.anexo.url);
      if (typeof url === "string" && url.trim().length > 0) {
        if (url.indexOf("blob") >= 0) {
          return url;
        }
        return FilesLib.downlaodFile(url, this.token);
      }
    },
    editaImagem() {
      this.$refs.EditaFoto.getBlob();
    },
    async visualizarAnexo(file) {
      if (file) {
        if (file.type === "doc") {
          window.open(this.formataUrl(file.url));
          return;
        }
        this.arquivoVisualizando = Object.assign({}, file);
        console.log(this.objEdit);

        await await axios
          .get(this.formataUrl(this.arquivoVisualizando.url), {
            responseType: "blob",
          })
          .then((res) => {
            const blob = new Blob([res.data], { type: res.data.type });
            // console.log(res);
            console.log(blob);

            this.objEdit.img = document.createElement("img");
            this.objEdit.img.src = (
              window.URL || window.webkitURL
            ).createObjectURL(blob);

            // this.objEdit.img.addEventListener("load", ok);

            const ok = () => {
              this.arquivoVisualizando.url = URL.createObjectURL(blob);
              this.abrirEdicao();
            };

            if (this.objEdit.img.addEventListener) {
              this.objEdit.img.addEventListener("load", ok);
            } else {
              this.objEdit.img.attachEvent("onload", ok);
            }

            // img.onload = function() {
            //   let w = img.width;
            //   let h = img.height;
            //   console.log("NEW IMAGE width", w);
            //   console.log("NEW IMAGE height: ", h);

            //   if (w > h) {
            //     window.ratio = 1;
            //   } else {
            //     window.ratio = 2;
            //   }
            // };
          })
          .catch((err) => {
            console.log("Erro ao baixar Imagem ->", err);
            this.showToast(
              "error",
              "Há algum problema com o esse anexo, consulte o T.I",
              3500
            );
          });
      }
    },

    handleImagemEditada(blob) {
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$bvModal.hide("modal-edicao");
        // console.log(blob.type);
        if (this.arquivoVisualizando) {
          // URL.revokeObjectURL(this.arquivoVisualizando.url);
          let arquivo = this.anexo.arquivos.find(
            (a) => a._id === this.arquivoVisualizando._id
          );
          // console.log("achou o arquivo", arquivo);
          arquivo.file = blob;
          arquivo._type = blob.type;
          let newUrl = URL.createObjectURL(
            new Blob([blob], { type: blob.type })
          );
          // console.log("newUrl", newUrl);
          this.$set(arquivo, "url", newUrl);
          // console.log("depois que editou", arquivo);
        }
      });
    },
    clearFiles() {
      this.$swal
        .fire({
          title: "Você tem certeza que deseja remover está imagem?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim. Excluir!",
          cancelButtonText: "Não.",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$refs["txtFile"].reset();
            this.anexo.url = "";
            this.$forceUpdate();
            this.formataUrl();
          }
        });
    },
    async downloadImg() {
      await axios
        .get(this.formataUrl(), { responseType: "blob" })
        .then((res) => {
          const blob = new Blob([res.data], { type: res.data.type });
          // console.log(res);
          // console.log(blob);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.anexo.url;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          console.log("Erro ao baixar Imagem ->", err);
          this.showToast(
            "error",
            "Há algum problema com o esse anexo, consulte o T.I",
            3500
          );
        });
    },
    fecharModal() {
      this.$emit("close");
    },
    excluir() {
      this.$emit("excluir", this.anexo.index);
    },
    async salvarDireto() {
      this.uploading = true;
      if (this.anexo.arquivos.length > 0) {
        this.$emit("loading", true);
        try {
          for (const arquivo of this.anexo.arquivos.filter(
            (a) => a.status === "added"
          )) {
            arquivo.url = (await FilesLib.uploadFile(arquivo.file)).data.file;

            delete arquivo.file;
            delete arquivo.status;
          }

          this.anexo.url = this.anexo.arquivos[0]?.url;

          this.$emit("salvo", {
            ...this.anexo,
            _rowStatus:
              this.editando && this.anexo._rowStatus != "added"
                ? "updated"
                : "added",
          });

          if (this.anexo && this.id_relacionamento && this.tipo) {
            let objAnexo = {
              ...this.anexo,
              tipo: this.tipo,
              relacionamento_id: this.id_relacionamento,
            };

            let result = await AnexosLib.store(objAnexo);
            if (result.success) {
              this.showToast("success", "Anexado com sucesso!", 3500);
            }
            // console.log("resul save anexo direto", result);
            this.$emit("anexou", { ...objAnexo, resultAPI: result });
          }
        } catch (error) {
          console.log(error);
          this.showToast(
            "error",
            "Ocorreu algum problema ao salvar o anexo, tente novamente mais tarde."
          );
        }
        this.$emit("loading", false);
      } else {
        this.showToast("error", "Selecione um arquivo antes de anexar!", 3500);
      }
      this.uploading = false;
    },
    async onSalvar() {
      //
      await this.salvarDireto();
    },
    setObservacao() {
      this.anexo.observacao = `Ficha médica ${
        this.pacienteNome ? "do paciente " + this.pacienteNome : ""
      } ${
        this.anexo?.data_referencia
          ? "referente à " +
            moment(this.anexo?.data_referencia)
              .format("DD/MM/YYYY")
              .toString()
          : ""
      }`;
      this.anexo.titulo = `Ficha médica do paciente ${this.pacienteNome}`;
    },
    onCancelar() {
      //
      this.$emit("close");
    },
    salvar() {
      //   console.log('salvando')

      this.$emit("salvar", {
        ...this.anexo,
        _rowStatus:
          this.editando && this.anexo._rowStatus != "added"
            ? "updated"
            : "added",
      });
    },
  },
  async mounted() {
    if (this.isFicha) {
      this.anexo.nivel = "secretaria";

      this.setObservacao(this.paciente, this.anexo.data_referencia);
    }
    if (typeof this.anexoEdit.index == "number") {
      this.editando = true;
      Object.assign(this.anexo, this.anexoEdit);
      this.anexo.data_referencia = this.anexo.data_referencia
        ? moment(this.anexo.data_referencia).format("YYYY-MM-DD")
        : "";
    }
  },
  computed: {
    niveis() {
      return niveis.sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  watch: {
    "anexo.data_referencia"() {
      if (this.anexo.data_referencia) {
        //
        this.setObservacao();
      }
    },
  },
};
</script>

<style scoped></style>
