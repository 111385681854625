<template>
  <div>
  
    <b-icon-upload /> Anexar
    <div class="files">
      <div
        class="file"
        v-for="file in listaFiltrada"
        @click.stop="$emit('fileClicked', file)"
        :key="`file_${listaFiltrada.indexOf(file)}`"
      >
        <!-- <b-skeleton height="100%"  /> -->
        <div style="height: 100%; !important; padding:3px; text-align:center; overflow:hidden">
          <img
            class="img-fluid img-thumbnail rounded"
            :src="formataUrl(file.url)"
            v-if="file.type === 'img'"
            
          />

          <i class="fa fa-file icon" v-if="file.type == 'doc'" />
          <div class="opc" @click.stop="remove(file)">
            <b-icon-trash-fill />
          </div>
        </div>
      </div>

      <div class="file plus" @click="openFile"><b-icon-plus-circle /></div>
    </div>
    <input
      type="file"
      class="fl"
      id="txtFile"
      @change="changeFile"
      ref="txtFile"
      style="visibility: hidden"
    />
  </div>
</template>

<script>
import FilesLib from "../../libs/FilesLib";
// import axios from "../../utils/axios";
export default {
  components: {},
  props: {
    lista: Array,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {
    // lista: function() {
    //   for (let file of this.lista.filter((x) => x.status == 0)) {
    //     let formData = new FormData();
    //     formData.append("file", file.file);
    //     file.status = 1;
    //     axios
    //       .post("/uploadFile", formData, {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //       })
    //       .then((x) => {
    //         console.log(axios.defaults);
    //         if (x.data) {
    //           formataUrl(file.url) = `${axios.defaults.baseURL}/download/${
    //             x.data
    //           }?token=${axios.defaults.headers["common"][
    //             "Authorization"
    //           ].replace("Bearer ", "")}`;
    //           file.status = 2;
    //         }
    //       });
    //   }
    // },
  },
  computed: {
    listaFiltrada() {
      return this.lista.filter((l) => l.status !== "deleted");
    },
  },
  methods: {
    openFile() {
      document.querySelector("#txtFile").click();
    },
    changeFile(ev) {
      this.$forceUpdate();
      // console.log(ev.target.files);
      if (ev.target.files && ev.target.files.length > 0) {
        for (let file of ev.target.files) {
          const _file = {
            name: file.name,
            status: "added",
            url: URL.createObjectURL(new Blob([file], { type: file.type })),
            type:
              file.name.toLowerCase().indexOf(".jpg") > -1 ||
              file.name.toLowerCase().indexOf(".jpeg") > -1 ||
              file.name.toLowerCase().indexOf(".png") > -1 ||
              file.name.toLowerCase().indexOf(".gif") > -1 ||
              file.name.toLowerCase().indexOf(".bmp") > -1
                ? "img"
                : "doc",
            file,
            _type: file.type,
          };
          this.$emit("addFile", _file);
        }
        ev.target.value = null;
      }
    },
    remove(file) {
      this.$emit("removeFile", file);
    },
    formataUrl(url) {
      // console.log(this.anexo.url);
      if (url) {
        if (typeof url === "string" && url.indexOf("blob") >= 0) {
          return url;
        }
        return FilesLib.downlaodFile(url, this.user.token);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.files {
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  // display: flex;
  // flex-direction: row;

  .file {
    cursor: pointer;
    margin: 5px;
    border: 1px solid #d8d8d8;
    background-color: #dedede;
    width: 30% !important;
    height: 120px;
    float: left;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .buttons {
    }

    .icon {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      font-size: 78px;
    }
    .opc {
      // display: none;

      z-index: 1;
      position: absolute;
      top: 30%;
      background-color: #ffffff60;
      color: white;
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      padding-top: 15px;
      font-size: 20px;
      display: none;

      text-align: center;
      border-radius: 100%;
      left: 45px;
      cursor: pointer;
      transition: all 0.1s ease;
      &:hover {
        background-color: #ffffff90;
        color: #444;
      }
    }
    &:hover {
      .opc {
        display: flex;
      }
    }
  }
  .plus {
    font-size: 78px;
    cursor: pointer;
    background-color: #f1f1f1;
    opacity: 0.3;
    border: none;
    color: #999;
    padding: 0px;
    text-align: center;
    padding-top: 0px !important;
    &:hover {
      opacity: 1;
      box-shadow: 0px 0px 6px #00000020;
    }
  }
  .fl {
    width: 0px;
    height: 0px;
    visibility: hidden !important;
  }
}
</style>
